import { Avatar, AvatarProps } from "@chakra-ui/react";

export type OfficialHeadProps = {
  avatarUrl?: string;
};
const avatarPropsFactory = (): AvatarProps => {
  const x = Math.random() * -4;
  const y = Math.random() * 3.5;
  return {
    size: "sm",
    border: "2px solid",
    borderLeftColor: "white",
    borderRightColor: "white",
    borderBottomColor: "white",
    borderTopColor: "white",
    zIndex: 2000,
    showBorder: true,
    pointerEvents: "all",
    boxShadow: "0px 1px 3px rgba(0,0,0,.35)",
    transform: `translate(${x * 10}px, ${y * 10}px)`,
  };
};

const OfficialHead = ({ avatarUrl }: OfficialHeadProps) => (
  <Avatar {...avatarPropsFactory()} src={avatarUrl} />
);

export default OfficialHead;
