import axios from "axios";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { WarmingScenarioVizProps } from "../components/WarmingScenarioViz";
import { Functions, ProbableFutureTilesets } from "../types";
import getFunctionUrl from "../util/getFunctionUrl";

const url = getFunctionUrl(Functions.CiceroSearch);

const queryTileset = (
  tileset: ProbableFutureTilesets,
  lon: number,
  lat: number
) =>
  axios.get(
    `https://api.mapbox.com/v4/${tileset}/tilequery/${lon},${lat}.json`,
    {
      params: {
        access_token: process.env.REACT_APP_PROBABLE_FUTURES_TOKEN,
      },
    }
  );

const useSearchQuery = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  return useQuery(
    ["search", search],
    async () => {
      const ciceroSearch = await axios.get(url, {
        params: {
          search,
        },
      });

      const {
        data: {
          location: { x: lon, y: lat },
        },
      } = ciceroSearch;
      const { data: data32 } = await queryTileset(
        ProbableFutureTilesets.DaysAbove32,
        lon,
        lat
      );
      const { data: data35 } = await queryTileset(
        ProbableFutureTilesets.DaysAbove35,
        lon,
        lat
      );
      const { data: data38 } = await queryTileset(
        ProbableFutureTilesets.DaysAbove38,
        lon,
        lat
      );
      const scenarios: [
        WarmingScenarioVizProps,
        WarmingScenarioVizProps,
        WarmingScenarioVizProps,
        WarmingScenarioVizProps,
        WarmingScenarioVizProps
      ] = [
        {
          label: "+1°C",
          daysAbove32: data32.features[0].properties.data_1c_mid,
          daysAbove35: data35.features[0].properties.data_1c_mid,
          daysAbove38: data38.features[0].properties.data_1c_mid,
        },
        {
          label: "+1.5°C",
          daysAbove32: data32.features[0].properties.data_1_5c_mid,
          daysAbove35: data35.features[0].properties.data_1_5c_mid,
          daysAbove38: data38.features[0].properties.data_1_5c_mid,
        },
        {
          label: "+2°C",
          daysAbove32: data32.features[0].properties.data_2c_mid,
          daysAbove35: data35.features[0].properties.data_2c_mid,
          daysAbove38: data38.features[0].properties.data_2c_mid,
        },
        {
          label: "+2.5°C",
          daysAbove32: data32.features[0].properties.data_2_5c_mid,
          daysAbove35: data35.features[0].properties.data_2_5c_mid,
          daysAbove38: data38.features[0].properties.data_2_5c_mid,
        },
        {
          label: "+3°C",
          daysAbove32: data32.features[0].properties.data_3c_mid,
          daysAbove35: data35.features[0].properties.data_3c_mid,
          daysAbove38: data38.features[0].properties.data_3c_mid,
        },
      ];
      return {
        ...ciceroSearch.data,
        scenarios,
      };
    },
    {
      enabled: !!search,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
    }
  );
};

export default useSearchQuery;
