import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
} from "react";

type Props = {
  n: number;
  children: ReactNode;
};

const Repeat = ({ n, children }: Props) => {
  const child = Children.only(children);
  return (
    <>
      {isValidElement(child) &&
        [...Array(n)].map((_, i) =>
          cloneElement(child, { key: i }, child.props.children)
        )}
    </>
  );
};

export default Repeat;
