import { Box, BoxProps, Icon } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import ReactMapGL, {
  AttributionControl,
  FlyToInterpolator,
  Marker,
  WebMercatorViewport,
} from "react-map-gl";
import { FaMapMarker } from "react-icons/fa";

import { SearchLocation } from "../../types";

const mapContainerProps: BoxProps = {
  flex: 1,
  alignSelf: "stretch",
  height: "100vh",
};

export type MapProps = {
  markerLocation?: SearchLocation;
  children?: ReactNode;
  onViewportChange?: (viewport: any) => void;
};

const DEFAULT_VIEWPORT = {
  latitude: 39.43,
  longitude: -98.54,
  zoom: 4,
};

const Map = ({ markerLocation, children, onViewportChange }: MapProps) => {
  const [viewport, setViewport] = useState<any>(DEFAULT_VIEWPORT);

  // This state serves as a latch for map transitions
  // It allows for the marker location to be separated from the logic of the transition
  // If the state is null it implies that the viewport does not need to be changed
  // If the state is not null, a transition is triggered and this state is then immediately nulled
  const [desiredViewportChange, setDesiredViewportChange] = useState<any>(null);

  useEffect(() => {
    onViewportChange && onViewportChange(viewport);
  }, [onViewportChange, viewport]);

  useEffect(() => {
    if (markerLocation) {
      setDesiredViewportChange({
        longitude: markerLocation.x,
        latitude: markerLocation.y,
        zoom: 10,
      });
    } else {
      setDesiredViewportChange(DEFAULT_VIEWPORT);
    }
  }, [markerLocation]);

  useEffect(() => {
    if (desiredViewportChange && viewport.width && viewport.height) {
      const nextViewport = new WebMercatorViewport({
        width: viewport.width,
        height: viewport.height,
        zoom: 10,
      });
      const [lon, lat] = nextViewport.getMapCenterByLngLatPosition({
        lngLat: [
          desiredViewportChange.longitude,
          desiredViewportChange.latitude,
        ],
        pos: [viewport.width / 2, viewport.height / 5],
      });
      setViewport((previousViewport: any) => ({
        ...previousViewport,
        latitude: lat,
        longitude: lon,
        zoom: desiredViewportChange.zoom,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      }));
      setDesiredViewportChange(null);
    }
  }, [viewport, desiredViewportChange]);

  return (
    <Box {...mapContainerProps}>
      <ReactMapGL
        {...viewport}
        mapStyle="mapbox://styles/azavea/cktopwjs404z417pehb3ug1ua"
        width="100%"
        height="100%"
        onViewportChange={(viewport: any) => setViewport(viewport)}
        attributionControl={false}
      >
        <AttributionControl
          compact={false}
          style={{
            fontSize: "10px",
            bottom: 0,
            right: 0,
          }}
          customAttribution="© Azavea © Probable Futures"
        />
        {markerLocation && (
          <Marker latitude={markerLocation.y} longitude={markerLocation.x}>
            <Icon as={FaMapMarker} fontSize="54px" ml="-27px" mt="-27px" />
          </Marker>
        )}
        {children}
      </ReactMapGL>
    </Box>
  );
};

export default Map;
