import { ChakraProvider, StackProps, VStack } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import About from "./panels/About";
import theme from "./theme";
import MapWrapper from "./wrappers/MapWrapper";
import SearchButtonWrapper from "./wrappers/SearchButtonWrapper";
import SearchResultPanelWrapper from "./wrappers/SearchResultPanelWrapper";
import LogoWrapper from "./wrappers/LogoWrapper";
import MapLegendWrapper from "./wrappers/MapLegendWrapper";

import "mapbox-gl/dist/mapbox-gl.css";

export const queryClient = new QueryClient();

const appContainerProps: StackProps = {
  flex: 1,
};

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <VStack {...appContainerProps}>
            <MapWrapper />
            <LogoWrapper />
            <About />
            <SearchResultPanelWrapper />
            <SearchButtonWrapper />
            <MapLegendWrapper />
          </VStack>
        </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
