import {
  SimpleGrid,
  SimpleGridProps,
  StackProps,
  VStack,
  Text,
  TextProps,
} from "@chakra-ui/react";

import Repeat from "../Repeat";
import Swatch from "../Swatch";

export type WarmingScenarioVizProps = {
  label: string;
  daysAbove32: number;
  daysAbove35: number;
  daysAbove38: number;
};

const containerProps: StackProps = {
  pb: 12,
  pt: 2,
  spacing: 3,
  position: "relative",
  _notLast: {
    borderRight: "1px solid",
    borderColor: "gray.200",
  },
  _first: {
    borderRight: "none",
  },
  // _before: {
  //   position: "absolute",
  //   content: "''",
  //   top: "20px",
  //   bottom: 0,
  //   left: "49%",
  //   width: "1px",
  //   bg: "gray.200",
  // },
};

const labelProps: TextProps = {
  position: "relative",
  fontWeight: 600,
  color: "#fff",
  fontSize: "xs",
  bg: "gray.900",
  padding: ".05em .35em",
  borderRadius: "2px",
  marginTop: "1em",
};

const gridProps: SimpleGridProps = {
  position: "relative",
  columns: 3,
  spacing: "1px",
  display: "inline-grid",
};

const countProps: TextProps = {
  position: "relative",
  fontWeight: 600,
  color: "gray.900",
  fontSize: "md",
  bg: "inherit",
};

const countLabelProps: TextProps = {
  position: "relative",
  fontWeight: 600,
  color: "gray.700",
  fontSize: "xs",
  bg: "inherit",
};

const WarmingScenarioViz = ({
  label,
  daysAbove32,
  daysAbove35,
  daysAbove38,
}: WarmingScenarioVizProps) => (
  <VStack {...containerProps}>
    <Text {...labelProps}>{label}</Text>
    <VStack>
      <VStack spacing={-1}>
        <Text {...countProps}>{daysAbove32}</Text>
        <Text {...countLabelProps}>days</Text>
      </VStack>

      <SimpleGrid {...gridProps}>
        <Repeat n={daysAbove32 - (daysAbove35 - daysAbove38)}>
          <Swatch color="wetBulb28" />
        </Repeat>
        <Repeat n={daysAbove35 - daysAbove38}>
          <Swatch color="wetBulb30" />
        </Repeat>
        <Repeat n={daysAbove38}>
          <Swatch color="wetBulb32" />
        </Repeat>
      </SimpleGrid>
    </VStack>
  </VStack>
);

export default WarmingScenarioViz;
