import {
  Box,
  BoxProps,
  HStack,
  Image,
  Link,
  LinkProps,
  StackProps,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";

const LABEL_WIDTH = "60px";

const containerProps: StackProps = {
  bg: "white",
  border: "1px solid",
  borderColor: "gray.100",
  borderRadius: "lg",
  spacing: 0,
  minWidth: "325px",
  width: "100%",
  maxWidth: "400px",
  alignItems: "stretch",
  overflow: "hidden",
  flexShrink: 0,
};

const cardSectionProps: BoxProps = {
  p: 4,
};

const detailStackProps: StackProps = {
  spacing: 4,
  alignItems: "baseline",
};

const textLabelProps: TextProps = {
  color: "gray.600",
  fontWeight: 600,
  fontSize: "xs",
  width: LABEL_WIDTH,
  textAlign: "right",
  flexShrink: 0,
};

const textValueProps: TextProps = {
  color: "gray.700",
  fontWeight: "500",
  fontSize: "sm",
  flex: "1",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const textLinkProps: LinkProps = {
  color: "gray.700",
  fontWeight: "500",
  fontSize: "sm",
  flex: "1",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export type OfficialCardProps = {
  title: string;
  districtLabel: string;
  name: string;
  party: string;
  phoneNumbers: string[];
  email: string;
  twitterHandle: string[];
  facebookUrl: string[];
  avatarUrl?: string;
};

const OfficialCard = ({
  title,
  name,
  party,
  districtLabel,
  phoneNumbers,
  email,
  twitterHandle,
  facebookUrl,
  avatarUrl,
}: OfficialCardProps) => (
  <VStack {...containerProps}>
    <Box {...cardSectionProps}>
      <HStack {...detailStackProps} alignItems="flex-start">
        <Box width={LABEL_WIDTH} flexShrink={0}>
          <Image width="100%" src={avatarUrl} />
        </Box>
        <VStack spacing={3} alignItems="flex-start">
          <VStack alignItems="flex-start" spacing={0}>
            <Text color="gray.500" fontWeight="600" fontSize="md">
              {title}
            </Text>
            <Text color="gray.900" fontWeight="600" fontSize="md">
              {name}
            </Text>
          </VStack>
          <VStack alignItems="flex-start" spacing={0}>
            <Text color="gray.700">{party}</Text>
            <Text color="gray.700">{districtLabel}</Text>
          </VStack>
        </VStack>
      </HStack>
    </Box>
    <Box {...cardSectionProps} bg="gray.50" flex="1">
      <VStack alignItems="stretch">
        {phoneNumbers.map((phone, i) => (
          <HStack {...detailStackProps} key={i}>
            <Text {...textLabelProps}>{i === 0 ? "Call" : ""}</Text>
            <Text {...textValueProps}>{phone}</Text>
          </HStack>
        ))}
        <HStack {...detailStackProps}>
          <Text {...textLabelProps}>Email</Text>
          <Link {...textLinkProps} mailto={email}>
            {email}
          </Link>
        </HStack>
        {twitterHandle.map((handle, i) => (
          <HStack {...detailStackProps} key={i}>
            <Text {...textLabelProps}>{i === 0 ? "Tweet" : ""}</Text>
            <Link {...textLinkProps} href={`https://www.twitter.com/${handle}`}>
              @{handle}
            </Link>
          </HStack>
        ))}
        {facebookUrl.map((url, i) => (
          <HStack {...detailStackProps} key={i}>
            <Text {...textLabelProps}>{i === 0 ? "Post" : ""}</Text>
            <Link {...textLinkProps} href={url}>
              {url.substring(12)}
            </Link>
          </HStack>
        ))}
      </VStack>
    </Box>
  </VStack>
);

export default OfficialCard;
