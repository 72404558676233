import { extendTheme } from "@chakra-ui/react";
import "@fontsource/inter/variable-full.css";
import "@fontsource/permanent-marker";

function getSize(size: string) {
  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(${size} / 2.5)`,
    },
    excessLabel: {
      width: size,
      height: size,
    },
    label: {
      fontSize: `calc(${size} / 2.5)`,
      lineHeight: size,
    },
  };
}

const theme = extendTheme({
  fonts: {
    body: "'InterVariable', system-ui, sans-serif",
    heading: "'InterVariable', system-ui, sans-serif",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.925rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    wetBulb28: "#f6d307",
    wetBulb30: "#f46e1e",
    wetBulb32: "#5b2929",
    wetBulb28Dark: "#917d04",
    wetBulb30Dark: "#c25718",
    wetBulb32Dark: "#5b2929",
    blue: {
      "50": "#E8F2FD",
      "100": "#BEDBF9",
      "200": "#94C4F5",
      "300": "#69ADF1",
      "400": "#3F96ED",
      "500": "#157FEA",
      "600": "#1165BB",
      "700": "#0D4C8C",
      "800": "#09335D",
      "900": "#04192F",
    },
    cyan: {
      "50": "#E5F7FF",
      "100": "#B8E9FF",
      "200": "#8ADBFF",
      "300": "#5CCCFF",
      "400": "#2EBEFF",
      "500": "#00B0FF",
      "600": "#008DCC",
      "700": "#006A99",
      "800": "#004666",
      "900": "#002333",
    },
    yellow: {
      "50": "#FDFDE7",
      "100": "#FAF9BC",
      "200": "#F8F591",
      "300": "#F5F166",
      "400": "#f0eb22",
      "500": "#EFE910",
      "600": "#BFBB0D",
      "700": "#8F8C0A",
      "800": "#605D06",
      "900": "#302F03",
    },
    orange: {
      "50": "#FEEFE7",
      "100": "#FCD3BB",
      "200": "#FAB78F",
      "300": "#F79B63",
      "400": "#F57F38",
      "500": "#f46e1e",
      "600": "#C24F0A",
      "700": "#923B07",
      "800": "#612705",
      "900": "#311402",
    },
    gray: {
      "50": "#F5F6F7",
      "100": "#D7DBDF",
      "200": "#CED2D6",
      "300": "#A5ACB6",
      "400": "#8B95A2",
      "500": "#747980",
      "600": "#5B6471",
      "700": "#444b54",
      "800": "#2D3239",
      "900": "#17191C",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 600,
        borderRadius: "full",
      },
      sizes: {},
    },
    Input: {
      variants: {
        rounded: {
          borderRadius: "full",
        },
      },
    },
    Select: {
      variants: {
        filled: {
          field: {
            borderRadius: "full",
            bg: "gray.500",
            color: "white",
            fontWeight: 600,
          },
          icon: {
            color: "white",
            fontWeight: 600,
          },
        },
      },
    },
    Avatar: {
      sizes: {
        xl: getSize("72px"),
      },
    },
    Modal: {
      variants: {
        drawerish: {
          dialogContainer: {
            pt: "40vh",
          },
        },
      },
    },
  },
});

export default theme;
