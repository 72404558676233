import { Box, Text } from "@chakra-ui/react";

export type SwatchProps = {
  color: string;
  size?: string;
  isRound?: boolean;
  label?: string;
};

const Swatch = ({
  color,
  size = "14px",
  isRound = false,
  label,
}: SwatchProps) =>
  label ? (
    <Box
      fontSize={size}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderColor={color}
      color={color}
      h={size}
      w={size}
      borderRadius={isRound ? "full" : undefined}
    >
      <Text fontSize="0.875em" fontWeight="600" lineHeight="1">
        {label}
      </Text>
    </Box>
  ) : (
    <Box
      bg={color}
      h={size}
      w={size}
      borderRadius={isRound ? "full" : undefined}
      display="inline-block"
    ></Box>
  );

export default Swatch;
