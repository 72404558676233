import { Icon, IconProps } from "@chakra-ui/react";

const ArrowIcon = (props: IconProps) => (
  <Icon viewBox="0 0 47 11" {...props} height="auto !important">
    <g
      id="2-Design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile-Search"
        transform="translate(-110.000000, -579.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Chart" transform="translate(11.000000, 165.000000)">
          <g id="Today" transform="translate(50.000000, 0.000000)">
            <path
              d="M90.6212951,424.912707 L95.91123,419.7108 C96.02959,419.594385 96.02959,419.405657 95.91123,419.289218 L90.6212704,414.087311 C90.5028858,413.970896 90.3109641,413.970896 90.1925795,414.087311 L89.6924482,414.579144 C89.5740883,414.695534 89.5740883,414.884262 89.6924482,415.000701 L93.6111296,418.854149 L49.3031278,418.643971 C49.1357266,418.643971 49,418.77744 49,418.942055 L49,419.637583 C49,419.802199 49.1357266,419.935667 49.3031278,419.935667 L93.6111296,420.145845 L89.6924482,423.999317 C89.5740883,424.115732 89.5740883,424.30446 89.6924482,424.420874 L90.1925795,424.912707 C90.3109641,425.029098 90.5028858,425.029098 90.6212951,424.912707 Z"
              id="arrow-right"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export default ArrowIcon;
