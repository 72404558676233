import {
  VStack,
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  SlideDirection,
  IconButton,
  Image,
  BoxProps,
  Box,
  Button,
  ButtonProps,
  ModalContentProps,
  ModalHeaderProps,
  TextProps,
  ModalBodyProps,
  StackProps,
  useMediaQuery,
  LinkProps,
  Link,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { FaInfo } from "react-icons/fa";

import locationExampleImage from "../../assets/location-example.png";
import azaveaLogo from "../../assets/azavea-logo.svg";
import ciceroLogo from "../../assets/cicero-logo.png";
import MapLegend from "../../components/MapLegend";

const buttonContainerPropsFactory = (
  placement: SlideDirection | undefined
): BoxProps => ({
  position: "absolute",
  left: 4,
  top: placement === "left" ? 6 : "auto",
  bottom: placement === "left" ? "auto" : 10,
});

const buttonPropsFactory = (
  isOpen: boolean,
  placement: SlideDirection | undefined
): ButtonProps => ({
  bg: "white",
  ...(!isOpen && {
    border: "3px solid",
    borderColor: "#000",
  }),
  color: "#000",
  size: placement === "left" ? "md" : "lg",
});

const modalContentProps: ModalContentProps = {
  bg: "gray.600",
  color: "white",
  maxWidth: "512px",
  borderTopRadius: "2xl",
};

const modalHeaderProps: ModalHeaderProps = {
  fontSize: "md",
  color: "gray.100",
  textAlign: "center",
  p: 6,
  pt: 9,
  lineHeight: 8,
  mb: 2,
  fontWeight: 600,
};

const modalBodyProps: ModalBodyProps = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  p: 0,
};

const contentStackProps: StackProps = {
  flex: 1,
  alignItems: "flex-start",
  spacing: 6,
  p: 4,
  maxWidth: "600px",
  margin: "0 auto",
};

const methodologyStackProps: StackProps = {
  maxWidth: "600px",
  my: 16,
  mx: 4,
  border: "1px solid",
  borderColor: "gray.300",
  borderTopRadius: "md",
  alignSelf: "center",
  spacing: 4,
  transform: "rotate(0.5deg)",
};

const repoBoxProps: BoxProps = {
  borderTop: "1px solid",
  borderColor: "gray.300",
  p: 4,
  mb: -4,
  mx: -4,
  alignSelf: "stretch",
  textAlign: "center",
};

const creditsStackProps: StackProps = {
  bg: "blackAlpha.500",
  px: 4,
  pt: 8,
  pb: 24,
  spacing: 16,
};

const contentTextProps: TextProps = {
  textAlign: "left",
  fontWeight: "400",
};

const methodologyTextProps: TextProps = {
  textAlign: "left",
  fontWeight: "400",
  fontSize: "sm",
  px: 4,
};

const entityStackProps: StackProps = {
  spacing: 5,
  maxWidth: "600px",
};

const entityDescProps: TextProps = {
  textAlign: "center",
  color: "gray.100",
};

const entityLinkProps: LinkProps = {
  color: "white",
  fontWeight: 600,
};

const About = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isFullsize] = useMediaQuery("(min-width: 1024px)");

  // The panel placement will change based on screen-size
  const placement: SlideDirection = useMemo(
    () => (isFullsize ? "left" : "bottom"),
    [isFullsize]
  );

  // Display icon-only buttons on smaller screens
  const showIconButtons = !isFullsize;

  return (
    <>
      <Drawer
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent {...modalContentProps}>
          <DrawerBody {...modalBodyProps}>
            <Box {...modalHeaderProps}>About this Site</Box>
            <VStack {...contentStackProps}>
              <Text {...contentTextProps}>
                Days with dangerously high temperatures are becoming more
                frequent in the United States as the planet gets hotter.
              </Text>
              <Text {...contentTextProps}>
                Combining Probable Futures climate change data with Cicero,
                Azavea’s database of elected officials, we’ve mapped “The 50
                Hottest Places in US Politics.”
              </Text>
              <MapLegend transform="rotate(0.5deg)" alignSelf="center" />
              <Text {...contentTextProps}>
                We show you where temperatures are rising fastest and make it
                easy to reach your elected officials.
              </Text>
              <Image
                alignSelf="center"
                src={locationExampleImage}
                maxWidth="200px"
              />
              <Text {...contentTextProps}>
                Use the search button to find your district and identify your
                elected officials. Then reach out and ask how they are planning
                to address the increase in heat!
              </Text>
            </VStack>
            <VStack {...methodologyStackProps}>
              <Text
                {...methodologyTextProps}
                fontWeight="600"
                fontSize="md"
                pt={4}
              >
                Methodology
              </Text>
              <Text {...methodologyTextProps}>
                We matched projected climate change data provided by{" "}
                <Link
                  {...entityLinkProps}
                  href="https://probablefutures.org/science/?tab=our-maps"
                >
                  Probable Futures and Woodwell Climate Research Center
                </Link>{" "}
                to U.S. counties.
              </Text>
              <Text {...methodologyTextProps}>
                We selected one county in each of the Contiguous United States
                as well as in Alaska and the District of Columbia that is
                projected to see the highest increase of days above 90°F (32°C)
                using a warming scenario of 2°C, as compared to historic levels.
              </Text>
              <Text {...methodologyTextProps}>
                For each county, we determined the most populous municipality
                using U.S. Census Bureau data. We used the Mapbox geocoder to
                find the coordinates of that municipality (usually the central
                business district). In a few cases where we couldn’t geocode,the
                centroid of the county was used instead.
              </Text>
              <Text {...methodologyTextProps}>
                We used the{" "}
                <Link
                  href="https://www.cicerodata.com/api/"
                  {...entityLinkProps}
                >
                  Cicero API
                </Link>{" "}
                for address-to-district matching and retrieving legislator data.
              </Text>
              <Box {...repoBoxProps}>
                <Link
                  href="https://www.github.com/azavea/hottest-politics"
                  fontWeight="600"
                >
                  github.com/azavea/hottest-politics
                </Link>
              </Box>
            </VStack>
            <VStack {...creditsStackProps}>
              <Text mb={-8} {...entityDescProps}>
                Built by
              </Text>
              <VStack {...entityStackProps}>
                <Image src={azaveaLogo} width="220px" />
                <Text {...entityDescProps}>
                  Azavea is a B-Corporation that creates advanced geospatial
                  technology and research for civic and social impact
                </Text>
                <Link {...entityLinkProps} href="https://www.azavea.com">
                  azavea.com
                </Link>
              </VStack>
              <VStack {...entityStackProps}>
                <Image src={ciceroLogo} width="220px" />
                <Text {...entityDescProps}>
                  Cicero is a global resource for elected officials and
                  legislative district boundaries. Cicero provides address to
                  district matching and legislator lookups at all levels of
                  government.
                </Text>
                <Link {...entityLinkProps} href="https://www.cicerodata.com">
                  cicerodata.com
                </Link>
              </VStack>
              <VStack {...entityStackProps}>
                <Text {...entityDescProps} fontSize="sm">
                  This website is based on data and other content made available
                  by Probable Futures, a Project of SouthCoast Community
                  Foundation and certain of that data may have been provided to
                  Probable Futures by Woodwell Climate Research Center, Inc. or
                  The Coordinated Regional climate Downscaling Experiment
                  (CORDEX).
                </Text>
                <Link
                  {...entityLinkProps}
                  href="https://www.probablefutures.org"
                >
                  probablefutures.org
                </Link>
              </VStack>
              <Text fontSize="sm" color="gray.200" textAlign="center">
                Favicon designed by OpenMoji. <br />
                License: CC BY-SA 4.0
              </Text>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box {...buttonContainerPropsFactory(placement)}>
        {isOpen ? (
          <Button
            aria-label="close the about panel"
            onClick={onClose}
            {...buttonPropsFactory(isOpen, placement)}
            zIndex={1600}
          >
            Return to map
          </Button>
        ) : showIconButtons ? (
          <IconButton
            aria-label="about this site"
            icon={<FaInfo />}
            onClick={onOpen}
            zIndex={1300}
            {...buttonPropsFactory(isOpen, placement)}
            size="sm"
          />
        ) : (
          <Button
            aria-label="about this site"
            leftIcon={<FaInfo />}
            onClick={onOpen}
            zIndex={1300}
            {...buttonPropsFactory(isOpen, placement)}
          >
            About
          </Button>
        )}
      </Box>
    </>
  );
};

export default About;
