import {
  VStack,
  Text,
  ModalBodyProps,
  ModalContentProps,
  StackProps,
  TextProps,
  Modal,
  ModalBody,
  ModalContent,
  SimpleGridProps,
  GridItem,
  SimpleGrid,
  useMediaQuery,
  LinkOverlay,
  LinkBox,
  Flex,
} from "@chakra-ui/react";

import OfficialHead from "../../components/OfficialHead";
import WarmingScenarioChart from "../../components/WarmingScenarioChart";
import { SearchResult } from "../../types";
import takeNRandom from "../../util/takeNRandom";
import OfficialCardWrapper from "../../wrappers/OfficialCardWrapper";

const modalContentProps: ModalContentProps = {
  m: "0",
  flex: 1,
  maxWidth: "100%",
  minHeight: "100%",
};

const modalHeaderProps: StackProps = {
  p: 4,
  mb: 2,
  spacing: 2,
};

const heading1Props: TextProps = {
  fontSize: "md",
  fontWeight: "600",
  color: "gray.500",
};

const heading2Props: TextProps = {
  fontSize: "lg",
  fontWeight: "bold",
  color: "gray.900",
};

const modalBodyProps: ModalBodyProps = {
  borderRadius: "2xl",
  bg: "white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  px: 0,
  pt: 4,
  pb: "92px !important",
  fontWeight: "600",
  margin: "0 auto !important",
  width: "100%",
  maxWidth: "800px !important",
};

const copyTextProps: TextProps = {
  textAlign: "left",
  fontWeight: "normal",
  color: "gray.700",
};

const contentStackProps: StackProps = {
  flex: 1,
  alignItems: "stretch",
  spacing: 8,
  p: 4,
  ...copyTextProps,
};

const callToActionContainerProps: SimpleGridProps = {
  columns: 10,
  width: "100%",
  maxWidth: "600px",
  spacing: 1,
  alignSelf: "center",
};

const outerOfficialsStackProps: StackProps = {
  bg: "white",
  zIndex: 2100,
  alignItems: "stretch",
  mx: -8,
  alignSelf: "center",
  mt: -8,
  pt: 8,
  spacing: 8,
};

const innerOfficialsStackProps: StackProps = {
  alignItems: "stretch",
};

const officialCardContainerPropsFactory = (
  isDoubleWide: boolean
): SimpleGridProps => ({
  flexWrap: "wrap",
  gridGap: 4,
  justifyContent: "center",
  columns: isDoubleWide ? 2 : 1,
});

export type SearchResultPanelProps = {
  onClose: () => void;
  isOpen: boolean;
  searchResult: SearchResult;
  truncate?: boolean;
};

const SearchResultPanel = ({
  searchResult,
  isOpen,
  onClose,
  truncate = false,
}: SearchResultPanelProps) => {
  const [isDoubleWide] = useMediaQuery("(min-width: 747px)");

  const stateOfficials = [
    ...takeNRandom(
      [
        ...searchResult.stateUpper,
        ...searchResult.stateLower,
        ...searchResult.stateExec,
      ].filter(
        (o) =>
          o.last_name !== "VACANT" &&
          o.office.title.toLowerCase() !== "governor"
      ),
      6
    ),
    ...[
      ...searchResult.stateUpper,
      ...searchResult.stateLower,
      ...searchResult.stateExec,
    ].filter((o) => o.office.title.toLowerCase() === "governor"),
  ];

  const nationalOfficials = takeNRandom(
    [...searchResult.nationalUpper, ...searchResult.nationalLower].filter(
      (o) => o.last_name !== "VACANT"
    ),
    6
  );

  const localOfficials = [
    ...takeNRandom(
      [...searchResult.local, ...searchResult.localExec].filter(
        (o) =>
          o.last_name !== "VACANT" && o.office.title.toLowerCase() !== "mayor"
      ),
      6
    ),
    ...[...searchResult.local, ...searchResult.localExec].filter(
      (o) => o.office.title.toLowerCase() === "mayor"
    ),
  ];

  const allOfficials = [
    ...localOfficials,
    ...stateOfficials,
    ...nationalOfficials,
  ];

  const displayedAddress = truncate
    ? searchResult.location.address.substr(
        searchResult.location.address.indexOf(",") + 1
      )
    : searchResult.location.address;

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      variant="drawerish"
      autoFocus={false}
    >
      <ModalContent {...modalContentProps}>
        <ModalBody {...modalBodyProps}>
          <VStack spacing={2} {...modalHeaderProps}>
            <Text {...heading1Props}>The Hottest Places in US Politics</Text>
            <Text {...heading2Props}>{displayedAddress}</Text>
          </VStack>
          <VStack {...contentStackProps}>
            <Text maxWidth={"600px"} margin="0 auto">
              Days with dangerously high temperatures become more frequent as
              the planet gets hotter.
              <br />
              The chart below shows the number of hot days in different warming
              scenarios ranging from 1°C (the recent past) to 3°C temperature
              increase, as compared to historic levels.
            </Text>
            <WarmingScenarioChart scenarios={searchResult.scenarios} />
            <SimpleGrid {...callToActionContainerProps}>
              <GridItem colSpan={3}>
                <Flex direction="column" alignItems="flex-end" pr={5}>
                  {allOfficials.map((o, i) => (
                    <OfficialHead avatarUrl={o.photo_origin_url} key={i} />
                  ))}
                </Flex>
              </GridItem>
              <GridItem colSpan={7} display="flex" flexDir="column">
                <VStack
                  alignItems="flex-start"
                  justifyContent="center"
                  maxWidth="350px"
                  marginTop="2em"
                  spacing={4}
                  flex="1"
                >
                  <Text fontSize="md" color="gray.700">
                    The time to act is now!
                  </Text>
                  <Text
                    fontFamily="'Permanent Marker'"
                    fontSize="2xl"
                    lineHeight="1.2"
                    color="gray.900"
                  >
                    Ask your elected officials how they are planning to keep
                    your community cool!
                  </Text>
                  <Text fontSize="sm">
                    Limiting emissions (or mitigation) is one focus area. But it
                    looks like collective inaction has made the 1.5° warming
                    scenario a reality — increasing green space, providing
                    access to cooling locations, and ensuring the energy grid
                    can handle more heat waves are other necessary adaptations.
                  </Text>
                </VStack>
              </GridItem>
            </SimpleGrid>
            <VStack {...outerOfficialsStackProps}>
              {localOfficials.length && (
                <VStack {...innerOfficialsStackProps}>
                  <Text fontWeight="600">Local officials</Text>
                  <SimpleGrid
                    {...officialCardContainerPropsFactory(isDoubleWide)}
                  >
                    {localOfficials.map((o, i) => (
                      <OfficialCardWrapper official={o} key={i} />
                    ))}
                  </SimpleGrid>
                </VStack>
              )}
              {stateOfficials.length && (
                <VStack {...innerOfficialsStackProps}>
                  <Text fontWeight="600">State officials</Text>
                  <SimpleGrid
                    {...officialCardContainerPropsFactory(isDoubleWide)}
                  >
                    {stateOfficials.map((o, i) => (
                      <OfficialCardWrapper official={o} key={i} />
                    ))}
                  </SimpleGrid>
                </VStack>
              )}
              {nationalOfficials.length && (
                <VStack {...innerOfficialsStackProps}>
                  <Text fontWeight="600">National officials</Text>
                  <SimpleGrid
                    {...officialCardContainerPropsFactory(isDoubleWide)}
                  >
                    {nationalOfficials.map((o, i) => (
                      <OfficialCardWrapper official={o} key={i} />
                    ))}
                  </SimpleGrid>
                </VStack>
              )}
            </VStack>
            <LinkBox
              as={VStack}
              border="1px solid"
              borderColor="gray.100"
              alignSelf="center"
              borderRadius="lg"
              width="100%"
              maxWidth="600px"
              p={2}
              spacing={0}
              _hover={{
                bg: "gray.50",
              }}
            >
              <Text mt={0}>See all of your elected officials on</Text>
              <LinkOverlay
                href={`https://live.cicerodata.com/live/local?lon=${searchResult.location.x}&lat=${searchResult.location.y}`}
                target="_blank"
              >
                <Text mt={0} fontWeight="600">
                  cicerodata.com
                </Text>
              </LinkOverlay>
            </LinkBox>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchResultPanel;
