import React from "react";

import OfficialCard from "../components/OfficialCard";
import { Official } from "../types";

export type OfficialCardWrapperProps = {
  official: Official;
};

const OfficialCardWrapper = ({ official }: OfficialCardWrapperProps) => {
  return (
    <OfficialCard
      title={official.office.title}
      districtLabel={official.office.district.label}
      party={official.party}
      name={`${official.first_name} ${official.last_name}`}
      phoneNumbers={official.addresses.map((a) => a.phone_1)}
      email={official.email_addresses[0]}
      twitterHandle={official.identifiers
        .filter((i) => i.identifier_type === "TWITTER")
        .map((i) => i.identifier_value)}
      facebookUrl={official.identifiers
        .filter(
          (i) =>
            i.identifier_type === "FACEBOOK-OFFICIAL" ||
            i.identifier_type === "FACEBOOK"
        )
        .map((i) => i.identifier_value)}
      avatarUrl={official.photo_origin_url}
    />
  );
};

export default OfficialCardWrapper;
