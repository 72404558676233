import {
  HStack,
  SimpleGridProps,
  SimpleGrid,
  VStack,
  Box,
  BoxProps,
  GridItem,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { useRef } from "react";
import useComponentSize from "@rehooks/component-size";

import Swatch, { SwatchProps } from "../Swatch";
import WarmingScenarioViz, {
  WarmingScenarioVizProps,
} from "../WarmingScenarioViz";
import ArrowIcon from "../ArrowIcon";

export type WarmingScenarioChartProps = {
  scenarios: [
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps
  ];
};

const containerProps: SimpleGridProps = {
  borderRadius: "lg",
  bg: "gray.50",
  columns: 5,
  width: "100%",
  maxWidth: "600px",
  spacing: 0,
};

const todayLabelGridProps: SimpleGridProps = {
  columns: 20,
  width: "100%",
  maxWidth: "600px",
};

const todayLabelPropsFactory = (extendLineLength: number): BoxProps => ({
  position: "relative",
  fontSize: "xs",
  fontWeight: "600",
  bg: "black",
  color: "white",
  borderRadius: "sm",
  px: 1,
  py: 0.25,
  display: "inline-block",
  _before: {
    position: "absolute",
    content: "''",
    top: "1.5em",
    bottom: `-${extendLineLength + 800}px`,
    left: "49%",
    width: "1px",
    bg: "transparent",
    borderLeft: "1px dashed",
    borderLeftColor: "gray.500",
    zIndex: 2000,
  },
});

const legendLabelProps: TextProps = {
  fontSize: "sm",
  fontWeight: "600",
};

const legendSwatchProps: Partial<SwatchProps> = {
  size: "8px",
  isRound: true,
};

const WarmingScenarioChart = ({ scenarios }: WarmingScenarioChartProps) => {
  const ref = useRef(null);
  const { height } = useComponentSize(ref);
  return (
    <VStack spacing={4}>
      <SimpleGrid {...todayLabelGridProps}>
        <GridItem colSpan={3} />
        <GridItem colSpan={2} textAlign="center">
          <Box {...todayLabelPropsFactory(height)}>Today</Box>
        </GridItem>
        <GridItem>
          <ArrowIcon fontSize="48px" color="gray.300" />
        </GridItem>
      </SimpleGrid>
      <SimpleGrid {...containerProps} ref={ref}>
        {scenarios.map((s, i) => (
          <WarmingScenarioViz key={i} {...s} />
        ))}
      </SimpleGrid>
      <HStack spacing={5}>
        <HStack alignItems="center" spacing={1}>
          <Swatch color="gray.500" label="1" size="16px" />
          <Text color="gray.600" {...legendLabelProps}>
            Day at or above
          </Text>
        </HStack>
        <HStack spacing={4}>
          <HStack spacing={1}>
            <Swatch {...legendSwatchProps} color="wetBulb28" />
            <Text color="wetBulb28Dark" {...legendLabelProps}>
              90°F
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Swatch {...legendSwatchProps} color="wetBulb30" />
            <Text color="wetBulb30Dark" {...legendLabelProps}>
              95°F
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Swatch {...legendSwatchProps} color="wetBulb32" />
            <Text color="wetBulb32Dark" {...legendLabelProps}>
              100°F
            </Text>
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default WarmingScenarioChart;
