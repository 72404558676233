import {
  Button,
  forwardRef,
  HStack,
  Icon,
  Input,
  Text,
  StackProps,
  useDisclosure,
  InputProps,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { FaSearch, FaRegTimesCircle, FaArrowLeft } from "react-icons/fa";
import React, { useCallback, useRef, useState } from "react";

export type SearchButtonProps = {
  onSearch: (query: string) => void;
  onClear: () => void;
  isLoading?: boolean;
  hasResults?: boolean;
  isError?: boolean;
};

const stackProps: StackProps = {
  display: "inline-flex",
  bg: "yellow.500",
  color: "black",
  fontSize: "lg",
  fontWeight: 600,
  height: "3em",
  borderRadius: "full",
  pointerEvents: "all",
  zIndex: 1600,
  boxShadow: "0 1px 5px 0 rgba(0,0,0,0.27)",
  _disabled: {
    opacity: 1,
  },
};

const inputProps: InputProps = {
  fontSize: "lg",
  color: "black",
  fontWeight: 600,
  border: "none",
  boxShadow: "none !important",
  _placeholder: {
    color: "rgba(0,0,0,.6)",
    fontWeight: 600,
  },
};

const SearchButton = forwardRef<SearchButtonProps, "div">(
  (
    {
      onSearch,
      onClear,
      hasResults = false,
      isLoading = false,
      isError = false,
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [query, setQuery] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    const onSubmitForm = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        onSearch(query);
        setQuery("");
        e.preventDefault();
      },
      [onSearch, query]
    );

    const onBlurInput = useCallback(() => {
      if (query === "") {
        onClose();
      }
    }, [query, onClose]);

    const onClickClearInput = useCallback(() => {
      setQuery("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [setQuery]);

    const onClickClearButton = useCallback(() => {
      setQuery("");
      onClear();
    }, [setQuery, onClear]);

    if (hasResults) {
      return (
        <HStack
          as={Button}
          ref={ref}
          {...stackProps}
          colorScheme="yellow"
          onClick={onClickClearButton}
          zIndex={1600}
        >
          <Text>Return to map</Text>
        </HStack>
      );
    }

    if (isError) {
      return (
        <HStack
          as={Button}
          ref={ref}
          {...stackProps}
          colorScheme="yellow"
          onClick={onClickClearButton}
          zIndex={1600}
        >
          <Icon as={FaArrowLeft} />
          <Text>No results found</Text>
        </HStack>
      );
    }

    return isOpen ? (
      <form onSubmit={onSubmitForm}>
        <HStack
          as={Button}
          ref={ref}
          {...stackProps}
          colorScheme="yellow"
          isLoading={isLoading}
          zIndex={1300}
        >
          <Icon as={FaSearch} />
          <InputGroup size="md">
            <Input
              variant="flushed"
              placeholder="Enter an address"
              onBlur={onBlurInput}
              autoFocus
              {...inputProps}
              value={query}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setQuery(e.target.value)
              }
              ref={inputRef}
            />
            <InputRightElement
              children={
                <Icon
                  as={FaRegTimesCircle}
                  onClick={onClickClearInput}
                  fontSize="lg"
                  marginRight="-1em"
                />
              }
            />
          </InputGroup>
        </HStack>
      </form>
    ) : (
      <HStack
        as={Button}
        ref={ref}
        {...stackProps}
        colorScheme="yellow"
        onClick={onOpen}
        isLoading={isLoading}
        zIndex={1300}
        spacing={3}
        lineHeight="1"
      >
        <Icon as={FaSearch} />
        <Text>Find a location</Text>
      </HStack>
    );
  }
);

export default SearchButton;
