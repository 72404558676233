import { WarmingScenarioVizProps } from "./components/WarmingScenarioViz";

export enum Functions {
  CiceroSearch = "cicero-search",
}

export enum ProbableFutureTilesets {
  DaysAbove32 = "probablefutures.40104-west-v1",
  DaysAbove35 = "probablefutures.40105-west-v1",
  DaysAbove38 = "probablefutures.40106-west-v1",
}

export type SearchLocation = {
  x: number;
  y: number;
  address: string;
};

export type Identifier = {
  identifier_type: "FACEBOOK-OFFICIAL" | "FACEBOOK" | "TWITTER" | "LINKEDIN";
  identifier_value: string;
};

export type Official = {
  first_name: string;
  last_name: string;
  office: {
    title: string;
    district: {
      district_type: string;
      label: string;
    };
  };
  addresses: {
    phone_1: string;
  }[];
  email_addresses: string[];
  photo_origin_url: string;
  party: string;
  identifiers: Identifier[];
};

export type SearchResult = {
  scenarios: [
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps,
    WarmingScenarioVizProps
  ];
  location: SearchLocation;
  localExec: Official[];
  local: Official[];
  stateExec: Official[];
  stateUpper: Official[];
  stateLower: Official[];
  nationalUpper: Official[];
  nationalLower: Official[];
};

export type OfficialMarkerData = {
  lon: number;
  lat: number;
  avatarUrl: string;
  address: string;
};
