import {
  Avatar,
  AvatarProps,
  Flex,
  FlexProps,
  HStack,
  StackProps,
} from "@chakra-ui/react";

export type OfficialMarkerProps = {
  rank?: number;
  avatarUrl?: string;
  zoom?: number;
};

const stackProps: StackProps = {
  spacing: -3,
  pointerEvents: "none",
  transform: "translate(-50%, -50%)",
};

const avatarProps: AvatarProps = {
  size: "xl",
  border: "4px solid",
  borderLeftColor: "white",
  borderRightColor: "white",
  borderBottomColor: "white",
  borderTopColor: "white",
  zIndex: 1000,
  showBorder: true,
  pointerEvents: "all",
  cursor: "pointer",
  _hover: {
    borderLeftColor: "yellow.500",
    borderRightColor: "yellow.500",
    borderBottomColor: "yellow.500",
    borderTopColor: "yellow.500",
  },
};

const flexProps: FlexProps = {
  fontSize: "lg",
  fontWeight: "bold",
  color: "black",
  borderRadius: "full",
  bg: "yellow.500",
  p: 2,
  border: "4px solid",
  borderColor: "white",
  zIndex: 1001,
  marginBottom: "-8px !important",
  pointerEvents: "all",
};

const OfficialMarker = ({ rank, avatarUrl }: OfficialMarkerProps) => {
  return (
    <HStack {...stackProps}>
      <Avatar {...avatarProps} src={avatarUrl} />
      {!!rank && <Flex {...flexProps}>#{rank}</Flex>}
    </HStack>
  );
};

export default OfficialMarker;
