import { VStack, StackProps } from "@chakra-ui/react";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import SearchButton from "../components/SearchButton";
import useSearchQuery from "../hooks/useSearchQuery";

const searchButtonContainerProps: StackProps = {
  position: "absolute",
  inset: 0,
  bottom: 8,
  top: "auto",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
};

const SearchButtonWrapper = () => {
  const [, setSearchParams] = useSearchParams();
  const searchQuery = useSearchQuery();

  const onSearch = useCallback(
    (search: string) => {
      setSearchParams({ search });
    },
    [setSearchParams]
  );

  const onClear = useCallback(() => {
    searchQuery.remove();
    setSearchParams({});
  }, [searchQuery, setSearchParams]);

  return (
    <VStack {...searchButtonContainerProps}>
      <SearchButton
        onSearch={onSearch}
        onClear={onClear}
        isLoading={searchQuery?.isLoading}
        hasResults={!!searchQuery?.data}
        isError={searchQuery.isError}
      />
    </VStack>
  );
};

export default SearchButtonWrapper;
