import { Marker } from "react-map-gl";
import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Map from "../components/Map";
import useSearchQuery from "../hooks/useSearchQuery";
import markers from "../assets/markers.json";
import OfficialMarker from "../components/OfficialMarker";
import { OfficialMarkerData } from "../types";

const typedMarkers = markers as OfficialMarkerData[];

const MapWrapper = () => {
  const [, setSearchParams] = useSearchParams();
  const searchQuery = useSearchQuery();
  const [zoomLevel, setZoomLevel] = useState();

  const onClickMarker = useCallback(
    (search: string) => {
      setSearchParams({ search, t: "y" });
    },
    [setSearchParams]
  );

  const allMarkers = useMemo(
    () => (
      <>
        {typedMarkers.map((m, i) => (
          <Marker
            longitude={m.lon}
            latitude={m.lat}
            key={i}
            onClick={() => onClickMarker(m.address)}
          >
            <OfficialMarker avatarUrl={m.avatarUrl} zoom={zoomLevel} />
          </Marker>
        ))}
      </>
    ),
    [onClickMarker, zoomLevel]
  );

  return (
    <Map
      markerLocation={searchQuery?.data?.location}
      onViewportChange={(viewport) =>
        viewport.zoom && setZoomLevel(viewport.zoom)
      }
    >
      {allMarkers}
    </Map>
  );
};

export default MapWrapper;
