import { Flex, FlexProps, Image, Slide } from "@chakra-ui/react";

import useSearchQuery from "../hooks/useSearchQuery";
import logo from "../assets/hottest-politics-logo.svg";

const containerProps: FlexProps = {
  position: "absolute",
  left: 0,
  right: 0,
  zIndex: "1200",

  pointerEvents: "none",
};

const innerContainerProps: FlexProps = {
  mt: 4,
  alignItems: "center",
  justifyContent: "center",
};

const LogoWrapper = () => {
  const { isSuccess } = useSearchQuery();

  return (
    <Flex {...containerProps}>
      <Slide direction="top" in={!isSuccess}>
        <Flex {...innerContainerProps}>
          <Image src={logo} />
        </Flex>
      </Slide>
    </Flex>
  );
};

export default LogoWrapper;
