import {
  Box,
  Flex,
  FlexProps,
  StackProps,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";

const containerProps: StackProps = {
  bg: "white",
  width: "198px",
  boxShadow: "base",
  borderRadius: "lg",
  p: 2,
};
const textProps: TextProps = {
  fontSize: "13px",
  fontWeight: 600,
  color: "blackAlpha.900",
};
const gradientBarProps: FlexProps = {
  background:
    "linear-gradient(90deg, rgba(255,77,0,0) 0%, rgba(255,77,0,1) 100%)",
  px: 2,
  py: 1,
  alignItems: "center",
};
const textShadowProps: TextProps = {
  textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
};

const MapLegend = (props: StackProps) => (
  <VStack {...containerProps} {...props}>
    <Text {...textProps}>
      Increase in days above 90°F on a 2°C warmer planet
    </Text>
    <Flex {...gradientBarProps} alignSelf="stretch">
      <Text {...textProps}>0</Text>
      <Box flex="1"></Box>
      <Text {...textProps} {...textShadowProps} color="white">
        43
      </Text>
    </Flex>
  </VStack>
);

export default MapLegend;
