import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";

// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
