import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import useSearchQuery from "../hooks/useSearchQuery";
import SearchResultPanel from "../panels/SearchResultPanel";

const SearchResultWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = useSearchQuery();

  const onClose = useCallback(() => {
    searchQuery.remove();
    setSearchParams({});
  }, [searchQuery, setSearchParams]);

  return searchQuery?.data ? (
    <SearchResultPanel
      searchResult={searchQuery.data}
      isOpen={!!searchQuery}
      onClose={onClose}
      truncate={!!searchParams.get("t")}
    />
  ) : (
    <></>
  );
};

export default SearchResultWrapper;
