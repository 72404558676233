import { StackProps, useMediaQuery } from "@chakra-ui/react";

import MapLegend from "../components/MapLegend";

const legendProps: StackProps = {
  transform: "rotate(0.5deg)",
  position: "absolute",
  left: 8,
  bottom: 12,
};

const MapLegendWrapper = () => {
  const [isFullsize] = useMediaQuery("(min-width: 1024px)");
  return isFullsize ? <MapLegend {...legendProps} /> : <></>;
};

export default MapLegendWrapper;
